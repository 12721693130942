<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ isAdd ? $t("group.add_group") : $t("group.edit_group") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('group.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Tenants -->
          <validation-provider #default="validationContext" name="id_tenant">
            <b-form-group
              class="text-capitalize"
              :label="$t('incident_list.tenant')"
              label-for="id_tenant"
              :state="getValidationState(validationContext)"

            >
              <v-select
                v-model="itemData.id_tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_tenant"
                @input="changeTenant"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Users -->
          <validation-provider #default="validationContext" name="users">
            <b-form-group
              class="text-capitalize"
              :label="$t('incident_list.users')"
              label-for="users"
              :state="getValidationState(validationContext)"
            >
            <div class="text-lowercase">
              <v-select
                v-model="userArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="users"
                class="text-lowercase"
                :create-option="option => ({value: option, label: option.toLowerCase()})"
              />
            </div>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{$t('actions.cancel')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref, computed } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    tenantOptions : {
      type: Array,
      required: true,
    },
    userOptions : {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {

    const tenantsArray = ref([]);
    const userArray = ref([]);

    const blankData = {
      name: "",
      id_user : null,
      id_tenant: null,
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));

    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const changeTenant = () => {
      console.log(itemData.value.id_tenant)
      store.dispatch('user_app/getAllForAdding', {id_tenant : itemData.value.id_tenant } )
      props.userOptions.value = store.getters['user_app/getAddingItems']
    }


    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        itemData.value.id_user = userArray;
        dispatch = store.dispatch("incident_list/add", itemData.value);
      } else {
        const req = {
          id: itemData.value.id,
          params: {
            name: itemData.value.name,
            id_user: userArray.value,
            id_tenant: itemData.value.id_tenant,
          },
        };

        dispatch = store.dispatch("incident_list/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          console.log({ response });
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
        itemData.value.id_tenant = props.itemEdit.tenant.id;
        userArray.value = props.itemEdit.users.map(
          (item) => item.ID
        );

      }else{
        tenantsArray.value = [],
        userArray.value = []
      }
    };

    return {
      tenantsArray,
      userArray,
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      changeTenant
    };
  },
};
</script>